<template>
  <TModal
    title="Schedule change"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButton
        content="Applies to all"
        icon="cil-check"
        @click="applyAll"
        :options="{
          disabled:
            loading || (!input.payment_due_date && !input.expected_delivery),
        }"
      />
    </template>
    <TInputDateTime
      label="Payment due date"
      class="mb-3"
      :value="input.payment_due_date"
      @update:value="input.payment_due_date = $event"
    />
    <TInputDateTime
      label="Expected delivery"
      :value="input.expected_delivery"
      @update:value="input.expected_delivery = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      input: {
        payment_due_date: 0,
        expected_delivery: 0,
      },
      loading: false,
    };
  },
  methods: {
    applyAll() {
      this.loading = true;
      Promise.all([
        this.items.forEach((item) => {
          this.$tomoni.order.purchase_items
            .update(item.id, this.lodash.pickBy(this.input))
            .then(({ data }) => {
              this.$store.commit("order.purchase_items.merge", data);
            });
        }),
      ])
        .then(() => {
          this.$store.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit("update:show", false);
          this.input = {
            payment_due_date: 0,
            expected_delivery: 0,
          };
        });
    },
  },
};
</script>
