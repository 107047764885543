<template>
  <CCard>
    <CCardHeader class="d-flex">
      <TMessage content="Tracking list" bold class="my-auto" />
      <TButton
        class="ml-auto"
        variant="outline"
        icon="cil-plus"
        content="Add tracking"
        @click="showAddTracking = true"
      />
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px" v-if="trackings">
      <div v-for="tracking in trackings" :key="tracking.id">
        <CCard accentColor="primary">
          <CCardBody class="p-2 d-flex">
            <SMessageTracking
              :value="tracking.code"
              :checked="tracking.received"
            />
            <div class="ml-auto d-flex">
              <TMessageDateTime :content="tracking.updated_at" small />
              <TButtonRemove
                class="ml-3"
                variant="outline"
                @click="removeTracking(tracking.id)"
              />
            </div>
          </CCardBody>
        </CCard>
      </div>
      <TMessageNoData v-if="!trackings.length" />
    </CCardBody>
    <SModalAddTracking
      :show.sync="showAddTracking"
      store="order.order_purchase"
    />
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    trackings: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      showAddTracking: false,
    };
  },
  computed: {
    ...mapGetters({
      purchase: "order.order_purchase.detail",
    }),
  },
  methods: {
    removeTracking(id) {
      this.$store
        .dispatch("order.order_purchase.detail.remove-tracking", {
          tracking_ids: [id],
        })
        .then(() => {
          this.$store.dispatch("order.order_purchase.detail.refresh");
        });
    },
  },
};
</script>
