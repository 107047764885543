<template>
  <div>
    <TMessage
      content="Attachments"
      creatable
      uppercase
      :addOverClasses="['d-inline-flex']"
      @click-create="showAttachment = true"
    >
      <template #suffix
        >:
        <div class="d-inline-flex flex-wrap">
          <div
            v-for="(attachment, index) in attachments"
            :key="index"
            class="d-flex mr-3"
          >
            <CIcon name="cil-file" class="my-auto ml-1 text-primary" />
            <TLink
              :content="attachment.id"
              :href="getUrlAttachment(attachment.path_file)"
              :messageOptions="{
                italic: true,
                bold: true,
                addOverClasses: ['ml-1 font-weight-bold'],
              }"
              removable
              @click-remove="deleteAttachment(attachment.id)"
            >
            </TLink>
            <span class="mx-1">-</span>
            <TMessageDateTime :content="attachment.voucher_date" dateOnly />
            <CLink
              class="ml-1 mb-1"
              @click="onShowReview(attachment)"
              v-if="checkMimeType(attachment.info_file)"
            >
              <CIcon name="cil-eye" />
            </CLink>
          </div>
          <TModal size="xl" title="Detail" :show.sync="showReview">
            <template #actions>
              <span></span>
            </template>
            <CImg
              style="object-fit: contain"
              thumbnail
              fluidGrow
              :src="getUrlAttachment(detail.path_file)"
              placeholderColor
              class="p-0 d-flex mx-auto"
            />
          </TModal>
          <!-- - Paginate -->
          <!-- - Over width -->
          <TMessageNotFound v-if="!attachments" class="ml-2" />
        </div>
      </template>
    </TMessage>
    <AddAttachment :show.sync="showAttachment" :purchase_id="purchase_id" />
  </div>
</template>

<script>
import AddAttachment from "./AddAttachment.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AddAttachment,
  },
  props: {
    purchase_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAttachment: false,
      showReview: false,
    };
  },
  created() {
    this.$store.dispatch("order.purchase_receipts.push-query", {
      "filter[purchase_id]": this.purchase_id,
    });
  },
  computed: {
    ...mapGetters({
      attachments: "order.purchase_receipts.list",
      detail: "order.purchase_receipts.detail",
    }),
  },
  methods: {
    getUrlAttachment(path_file) {
      return process.env.VUE_APP_ORDER_SERVICE_HOST + "/files/" + path_file;
    },
    deleteAttachment(id) {
      this.$store.dispatch("order.purchase_receipts.delete", id);
    },
    checkMimeType(type) {
      return ["image/png", "image/jpeg", "image/webp", "image/tiff"].includes(
        type.mime_type
      );
    },
    onShowReview(att) {
      this.$store.commit("order.purchase_receipts.select", att.id);
      this.showReview = true;
    },
  },
};
</script>
