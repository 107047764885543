<template>
  <TTabs :tabs="tabs" @change-tab="changeTab" :activeTab="$route.query.tab">
    <template #detail>
      <Purchase />
    </template>
    <template #logs>
      <SCardLog :logableId="id" />
    </template>
    <template #transactions>
      <SCardTransaction :objectableId="id" />
    </template>
    <template #tracking>
      <Tracking :trackings="purchase.trackings"/>
    </template>
    <template #tracking-badge>
      <CBadge color="primary">{{ purchase.trackings.length }}</CBadge>
    </template>
  </TTabs>
</template>
<script>
import Tracking from "./components/Tracking.vue";
import Purchase from "./components/Purchase.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Purchase,
    Tracking,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "tracking", name: "Tracking", icon: "cis-queue" },
        {
          key: "logs",
          name: "Logs",
          icon: "cis-note",
        },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("order.order_purchase.detail.fetch", this.id);
      this.$store.dispatch("order.purchase_items.push-query", {
        "filter[purchase_id]": this.id,
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapGetters({
      purchase: "order.order_purchase.detail",
    }),
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
