<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center flex-wrap">
      <strong>{{ purchase.id }} </strong>
      <SMessageOrderStatus
        :id="purchase.status_id"
        store="order.purchase_statuses"
        class="ml-1"
      />
      <TSpinner :loading="loading" />
      <div class="ml-auto">
        <SButtonStatus
          v-for="status in purchase.steps_back"
          :key="status.id"
          :id="status"
          store="order.purchase_statuses"
          @click="updatePurchase('previous_status', status)"
          :disabled="updating"
        />
        <CIcon
          v-if="!lodash.isEmpty(purchase.steps_back)"
          name="cil-action-undo"
          class="ml-2 mr-1"
          style="transform: rotate(90deg)"
        />
        <SButtonStatus
          v-for="status in purchase.steps_forward"
          :key="status.id"
          :id="status"
          store="order.purchase_statuses"
          @click="updatePurchase('next_status', status)"
          :disabled="updating"
        />
        <span class="mx-2">|</span>
        <SButtonDebit
          @click="debit"
          class="mr-1"
          :options="{ disabled: updating }"
          v-if="purchase.unsolved && purchase.status_id == 'Debited'"
        />
        <TButton
          content="Export excel"
          icon="cid-cloud-download"
          size="sm"
          @click="exportExcel"
        />
        <TButton
          v-if="purchaseItems.length"
          icon="cilCalendarCheck"
          size="sm"
          color="info"
          variant="outline"
          class="ml-1"
          tooltip="Schedule change"
          @click="showEditDates = true"
        />
        <TButtonRefresh
          :options="{ disabled: loading }"
          class="ml-1"
          @click="
            $store.dispatch('order.order_purchase.detail.fetch', id);
            $store.dispatch('order.purchase_items.fetch');
          "
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow class="mb-4">
        <CCol sm="12" md="6" lg="4" class="mb-3">
          <TTableAsForm title="Details" :data="purchase" :fields="detailFields">
            <template #id="{ value }">
              <TMessage :content="value" noTranslate bold />
            </template>
            <template #supplier_id="{ value, data }">
              <SMessageSupplier
                v-if="data.supplier"
                :to="lodash.getReferenceLink('supplier',lodash.toString(value) )"
                :content="data.supplier.name"
                :id="data.supplier_id"
                :editable="!updating"
                @change="
                  updatePurchase('supplier_id', $event).then(() => {
                    purchaseRefresh();
                  })
                "
              />
              <TMessageNotFound v-else :slug="value" />
            </template>
            <template #buyer_id="{ value }">
              <TLink
                v-if="purchase.buyer"
                :to="lodash.getReferenceLink('user', value)"
                :content="purchase.buyer.name"
                :messageOptions="{ truncate: 1 }"
              />
            </template>
            <template #organization_id="{ value }">
              <SMessageOrganization :id="value" />
            </template>
            <template #created_at="{ value }">
              <TMessageDateTime :content="value" small />
            </template>
            <template #updated_at="{ value }">
              <TMessageDateTime :content="value" small />
            </template>
            <template #debited_at>
              <TMessageDateTime v-if="debited_at" :content="debited_at" small />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="4" class="mb-3">
          <TTableAsForm
            title="Purchase cost"
            :data="purchase"
            :fields="costOfGoodsFields"
          >
            <template #balance="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
            <template #sub_total="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
            <template #paid="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
            <template #outstanding="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
            <template #unsolved="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
            <template #debited="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol sm="12" md="6" lg="4" class="mb-3">
          <TTableAsForm
            title="Other fees"
            :data="purchase"
            :fields="costFields"
          >
            <template #additional_cost="{ value }">
              <TMessageMoney
                :amount="value"
                :editable="!updating"
                @change="updatePurchase('additional_cost', $event)"
                :currency="purchase.currency_id"
              />
            </template>
            <template #compensation="{ value }">
              <TMessageMoney :amount="value" :currency="purchase.currency_id" />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol class="mb-3 mt-2">
          <Attachments :purchase_id="id" />
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol>
          <TTableAdvance
            :items="purchaseItems"
            :fields="itemFields"
            store="order.purchase_items"
            resource="purchase/purchases"
            title="Items"
            noFilter
            removable
            creatable
            @click-create="showCreateItem = true"
          >
            <template #append-actions="{ item }">
              <CCol class="px-1 text-center" col="12">
                <SButtonDistribution @click="clickDistribution(item.id)" />
              </CCol>
            </template>
            <template #product="{ item }">
              <td>
                <SCardProductItems
                  v-if="item"
                  :item="item.product"
                  widthAuto
                  resource="/purchase/goods/products"
                />
              </td>
            </template>
            <template #price="{ item }">
              <td>
                <TMessageMoney
                  :decimalLimit="6"
                  :amount="item.price"
                  :editable="!purchaseItemLoading"
                  @change="updatePurchaseItem('price', $event)"
                  :currency="purchase.currency_id"
                />
              </td>
            </template>
            <template #price_with_tax="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.price_with_tax"
                  :currency="purchase.currency_id"
                />
              </td>
            </template>
            <template #quantity="{ item }">
              <td>
                <TTableAsForm
                  :data="item"
                  :fields="[
                    { key: 'quantity', label: 'Purchase' },
                    {
                      key: 'distributed_quantity',
                      label: 'Distributed',
                    },
                    {
                      key: 'remaining_distributed_quantity',
                      label: 'Remaining',
                    },
                    {
                      key: 'received_quantity',
                      label: 'Received at the warehouse',
                    },
                  ]"
                  :splitLeft="6"
                  style="min-width: 250px; font-size: 15px"
                  :addRowClasses="['']"
                >
                  <template #quantity="{ value }">
                    <TMessageNumber
                      :value="value"
                      :editable="!purchaseItemLoading"
                      @change="updatePurchaseItem('quantity', $event)"
                    />
                  </template>
                  <template #distributed_quantity="{ value }">
                    <TMessageNumber :value="value" />
                  </template>
                  <template #remaining_distributed_quantity="{ value }">
                    <TMessageNumber :value="value" />
                  </template>
                  <template #received_quantity="{ value }">
                    <TMessageNumber :value="value" />
                  </template>
                </TTableAsForm>
              </td>
            </template>
            <template #tax_percent="{ item }">
              <td>
                <TMessagePercent
                  :value="item.tax_percent"
                  :editable="!purchaseItemLoading"
                  @change="updatePurchaseItem('tax_percent', $event)"
                />
              </td>
            </template>
            <template #amount="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.amount"
                  :currency="purchase.currency_id"
                />
              </td>
            </template>
            <template #tax="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.tax"
                  :currency="purchase.currency_id"
                />
              </td>
            </template>
            <template #balance="{ item }">
              <td>
                <TMessageMoney
                  :amount="item.balance"
                  :currency="purchase.currency_id"
                />
              </td>
            </template>
            <template #date="{ item }">
              <td>
                <TTableAsForm
                  :data="item"
                  :fields="[
                    { key: 'payment_due_date', label: 'Payment due date' },
                    {
                      key: 'expected_delivery',
                      label: 'Expected delivery',
                    },
                  ]"
                  :splitLeft="6"
                  :addRowClasses="['pb-1']"
                >
                  <template #payment_due_date>
                    <TMessageDateTime
                      dateOnly
                      showEditModal
                      :content="item.payment_due_date"
                      :editable="!purchaseItemLoading"
                      @change="updatePurchaseItem('payment_due_date', $event)"
                    />
                  </template>
                  <template #expected_delivery>
                    <TMessageDateTime
                      dateOnly
                      showEditModal
                      :content="item.expected_delivery"
                      :editable="!purchaseItemLoading"
                      @change="updatePurchaseItem('expected_delivery', $event)"
                    />
                  </template>
                </TTableAsForm>
              </td>
            </template>
            <template #note="{ item }">
              <td>
                <TMessageText
                  :value="item.note"
                  :editable="!purchaseItemLoading"
                  :messageOptions="{ truncate: 3 }"
                  @change="updatePurchaseItem('note', $event)"
                />
              </td>
            </template>
          </TTableAdvance>
          <AddItem
            :show.sync="showCreateItem"
            @click-add="addItem"
            :key="key"
            :currency_id="purchase.currency_id"
          />
          <Distribution
            v-if="!lodash.isEmpty(purchase)"
            :show.sync="showDistribution"
            :purchase_item="id"
            :organization_id="purchase.organization_id"
          />
          <ChangeDates :items="purchaseItems" :show.sync="showEditDates" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from "vuex";
import AddItem from "./AddItem.vue";
import Attachments from "./Attachments.vue";
import Distribution from "./Distribution.vue";
import ChangeDates from "./ChangeDates.vue";
export default {
  components: {
    Distribution,
    Attachments,
    AddItem,
    ChangeDates,
  },
  data() {
    return {
      itemFields: [
        { key: "_", _style: "width: 45px" },
        { key: "product", label: "Product", _style: "min-width: 350px" },
        {
          key: "price",
          label: "Price without tax",
          _style: "min-width: 190px",
        },
        { key: "price_with_tax", label: "Price with tax" },
        { key: "quantity", label: "Quantity", _style: "min-width: 360px" },
        { key: "amount", label: "Sub total" },
        { key: "tax_percent", label: "Tax percent" },
        { key: "tax", label: "Tax" },
        { key: "balance", label: "Total payment" },
        {
          key: "date",
          label: "Dates",
          _style: "min-width: 380px",
        },
        {
          key: "note",
          label: "Note",
          _style: "min-width: 300px",
        },
      ],
      detailFields: [
        { key: "id", label: "Order Id" },
        { key: "supplier_id", label: "Supplier" },
        { key: "organization_id", label: "Organization" },
        { key: "buyer_id", label: "Buyer" },
        { key: "created_at", label: "Created at" },
        { key: "updated_at", label: "Latest update" },
        { key: "debited_at", label: "Debited at" },
      ],
      costOfGoodsFields: [
        { key: "balance", label: "Total amount" },
        { key: "sub_total", label: "Cost of goods" },
        { key: "paid", label: "Paid" },
        { key: "outstanding", label: "Outstanding" },
        { key: "debited", label: "Debited" },
        { key: "unsolved", label: "Unsolved" },
      ],
      costFields: [
        { key: "additional_cost", label: "Additional cost" },
        { key: "compensation", label: "Compensation" },
      ],
      showCreateItem: false,
      showDistribution: false,
      showEditDates: false,
      key: "add-item",
    };
  },
  computed: {
    ...mapGetters({
      purchase: "order.order_purchase.detail",
      purchaseItems: "order.purchase_items.list",
      purchaseItemLoading: "order.purchase_items.detail.loading",
      updating: "order.order_purchase.detail.updating",
      purchaseLoading: "order.order_purchase.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
    loading() {
      return this.purchaseItemLoading || this.purchaseLoading;
    },
    debited_at() {
      return (
        this.purchase?.transactions?.find((x) => x.type_id == "debit-purchase")
          ?.created_at || ""
      );
    },
  },
  methods: {
    clickDistribution(item_id) {
      this.$store.commit("order.purchase_items.select", item_id);
      this.showDistribution = true;
    },
    addItem(item) {
      const data = { ...item, purchase_id: this.id };
      this.$store.dispatch("order.purchase_items.create", data).then(() => {
        this.showCreateItem = false;
        this.reset();
        this.purchaseItemRefresh();
      });
    },
    reset() {
      this.key = this.lodash.resetKey("add-item");
    },
    purchaseRefresh() {
      this.$store.dispatch("order.order_purchase.detail.fetch", this.id);
    },
    purchaseItemRefresh() {
      this.$store.dispatch("order.purchase_items.fetch");
    },
    updatePurchaseItem(field, value) {
      this.$store.dispatch("order.purchase_items.detail.update", {
        [field]: value,
      });
    },
    updatePurchase(field, value) {
      return this.$store.dispatch("order.order_purchase.detail.update", {
        [field]: value,
      });
    },
    exportExcel() {
      this.$store
        .dispatch("helper.exports.export-purchase", {
          id: this.id,
        })
        .then(({ data, filename }) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = filename;
          link.click();
        });
    },
    debit() {
      this.$store
        .dispatch("order.order_purchase.detail.update", {
          action_debit: true,
        })
        .then(() => {
          this.purchaseRefresh();
        });
    },
  },
};
</script>
