<template>
  <TModal
    title="Attachment"
    :show="show"
    :creating="creating"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="modalKey"
  >
    <TTableAsForm :data="attachment" :fields="fields" :splitLeft="4">
      <template #id="{ value }">
        <TInputText :value="value" @update:value="attachment.id = $event" />
      </template>
      <template #voucher_date="{ value }">
        <TInputDateTime
          :value="value"
          @update:value="attachment.voucher_date = $event"
        />
      </template>
      <template #file>
        <TInputFile
          :key="inputFileKey"
          @update:value="changeFile"
          :multiple="false"
        />
      </template>
    </TTableAsForm>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
import lodash from "@/core/plugins/lodash"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    purchase_id: {
      type: String,
      required: true,
    },
  },
  defaultAttachment: {
    id: null,
    purchase_id: null,
    voucher_date: lodash.toTimeStamp(Date.now()),
    file: null,
  },
  data() {
    return {
      attachment: this.defaultAttachment(),
      fields: [
        { key: "file", label: "File" },
        { key: "id", label: "Voucher code" },
        { key: "voucher_date", label: "Voucher date" },
      ],
      inputFileKey: "inputfilekey",
      modalKey: "",
    };
  },
  computed: {
    ...mapGetters({
      creating: "order.purchase_receipts.creating",
    }),
  },
  methods: {
    create() {
      this.$store
        .dispatch("order.purchase_receipts.create", this.attachment)
        .then(() => {
          this.attachment = this.defaultAttachment();
          this.inputFileKey = "" + this.lodash.random(10000, 100000);
          this.$emit("update:show", false);
          this.modalKey = this.lodash.resetKey("modal-key");
        });
    },
    defaultAttachment() {
      return {
        ...this.$options.defaultAttachment,
        purchase_id: this.purchase_id,
      };
    },
    changeFile(file) {
      if (file.length > 0) this.attachment.file = file[0].file;
      else this.attachment.file = null;
    },
  },
};
</script>
