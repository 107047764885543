<template>
  <TModal
    title="Distribution"
    :show="show"
    size="xl"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
  >
    <CCol col="12" class="mt-3">
      <TTableAdvance
        :items="distributions"
        :fields="distributionFields"
        store="order.distributions"
        resource=""
        title="Distributed orders"
        noFilter
        removable
        @click-add="addDistribution"
        @row-removed="removeDistribution"
      >
        <!-- - paginate -->
        <template #order_id="{ item }">
          <td>
            <TLink
              v-if="item.order"
              :content="item.order.id"
              :messageOptions="{ bold: true }"
              :to="lodash.getReferenceLink('order', item.order.id)"
            />
          </td>
        </template>
        <template #customer_id="{ item }">
          <td>
            <TMessage
              :content="item.customer ? item.customer.name : '...'"
              noTranslate
              bold
            />
          </td>
        </template>
        <template #order_quantity="{ item }">
          <td>
            <TMessageNumber
              v-if="item.order_item"
              :value="item.order_item.quantity"
            />
          </td>
        </template>
        <template #remaining_quantity="{ item }">
          <td>
            <TMessageNumber
              v-if="item.order_item"
              :value="item.order_item.remaining_distributed_quantity"
            />
          </td>
        </template>
        <template #distribution_quantity="{ item }">
          <td>
            <TMessageNumber
              :value="item.quantity"
              editable
              @change="onUpdateQuantity"
            >
              <template #suffix>
                <label
                  class="small text-muted"
                  v-if="purchaseItem.remaining_distributed_quantity > 0"
                  v-c-tooltip="{
                    content: $t('Remaining distributable'),
                  }"
                >
                  (+{{ purchaseItem.remaining_distributed_quantity }})</label
                >
              </template>
            </TMessageNumber>
          </td>
        </template>
        <template #supply_sale_price="{ item }">
          <td>
            <TMessageMoney
              :amount="item.price"
              :currency="purchaseItem.currency_id"
              editable
              @change="onUpdatePrice"
            />
          </td>
        </template>
      </TTableAdvance>
    </CCol>
    <CCol col="12">
      <TTableAdvance
        :items="orderItems"
        :fields="remainingOrderFields"
        store="order.distributions"
        resource=""
        title="Remaining orders"
        noFilter
        addable
        class="mt-3"
        @click-add="addDistribution"
      >
        <!-- - paginate -->
        <template #customer_id="{ item }">
          <td>
            <TMessage
              :content="item.customer ? item.customer.name : '...'"
              noTranslate
              bold
            />
          </td>
        </template>
        <template #supply_sale_price="{ item }">
          <td>
            <TInputMoney
              :value.sync="item.supply_sale_price"
              :currency="purchaseItem.currency_id"
            />
          </td>
        </template>
        <template #distribution_quantity="{ item }">
          <td>
            <TInputNumber :value.sync="item.distribution_quantity" />
          </td>
        </template>
      </TTableAdvance>
    </CCol>
    <template #actions><div></div></template>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      remainingOrderFields: [
        { key: "order_id", label: "Order" },
        { key: "customer_id", label: "Customer" },
        { key: "quantity", label: "Order quantity" },
        {
          key: "distributed_quantity",
          label: "Distributed quantity",
        },
        {
          key: "remaining_distributed_quantity",
          label: "Remaining quantity",
        },
        { key: "distribution_quantity", label: "Distribution quantity" },
        { key: "supply_sale_price", label: "Supply sale price" },
        { key: "_" },
      ],
      distributionFields: [
        { key: "_" },
        { key: "order_id", label: "Order" },
        { key: "customer_id", label: "Customer" },
        { key: "order_quantity", label: "Order quantity" },
        {
          key: "remaining_quantity",
          label: "Remaining quantity",
        },
        {
          key: "distribution_quantity",
          label: "Distribution quantity",
        },
        { key: "supply_sale_price", label: "Supply sale price" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      purchaseItem: "order.purchase_items.detail",
      distributions: "order.distributions.list",
      orderItems: "order.order_items.list",
    }),
  },
  watch: {
    show(show) {
      if (show) {
        this.fetchOrderItems();
        this.fetchDistributions();
      }
    },
  },
  methods: {
    fetchOrderItems() {
      this.$store.dispatch("order.order_items.apply-query", {
        "filter[available_for_distribution]": 1,
        "filter[available_for_distribution_for_purchase_item]":
          this.purchaseItem.id,
        "filter[product_id]": this.purchaseItem.product_id,
        "filter[order.sales_organization_id]": this.organization,
        include: "distributions,customer",
      });
    },
    fetchDistributions() {
      this.$store.dispatch("order.distributions.push-query", {
        "filter[purchase_item_id]": this.purchaseItem.id,
        include: "orderItem,order",
        append: "customer",
      });
    },
    refreshPurchaseItems() {
      this.$store.dispatch("order.purchase_items.fetch");
    },
    addDistribution(orderItem) {
      this.$store
        .dispatch("order.distributions.create", {
          order_item_id: orderItem.id,
          purchase_item_id: this.purchaseItem.id,
          quantity: orderItem.distribution_quantity,
          price: orderItem.supply_sale_price,
        })
        .then(() => {
          this.fetchOrderItems();
          this.fetchDistributions();
          this.refreshPurchaseItems();
        });
    },
    onUpdateQuantity(value) {
      this.$store
        .dispatch("order.distributions.detail.update", {
          quantity: value,
        })
        .then(() => {
          this.fetchOrderItems();
          this.fetchDistributions();
          this.refreshPurchaseItems();
        });
    },
    onUpdatePrice(value) {
      this.$store
        .dispatch("order.distributions.detail.update", {
          price: value,
        })
        .then(() => {
          this.fetchOrderItems();
        });
    },
    removeDistribution() {
      this.fetchOrderItems();
      this.refreshPurchaseItems();
    },
  },
};
</script>
